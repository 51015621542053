<template>
  <div class="wap-collect">
    <headerBar :title="$t('guan-zhu-dian-pu-1')" @back="back"></headerBar>

    <div class="shop-list">
      <List
        v-if="dataList.length > 0"
        v-model="loading"
        :finished="finished"
        :finished-text="$t('common.noMore')"
        @load="onLoad"
      >
        <div class="shop" v-for="(item, i) in dataList" :key="i">
          <div class="flex-center top">
            <div class="img">
              <img :src="item.Shop.Logo" />
            </div>
            <div class="flex-1 flex-center-between">
              <div class="flex-1">
                <div class="name">{{ item.Shop.Name }}</div>
                <div class="label">
                  {{ $t('ping-fen') }}
                  {{ item.Shop.GoodsScore }}
                </div>
              </div>
              <Button @click="toShop(item)"
                >{{ $t('fang-wen-shang-dian')
                }}<i class="el-icon-arrow-right"></i
              ></Button>
            </div>
          </div>

          <div class="flex-center-between bottom">
            <div class="info">
              {{ $t('yi-shou') }}
              {{ item.Shop.TotalDeal }}
            </div>
          </div>
        </div>
      </List>

      <div class="empty-box flex-column-center" v-if="dataList.length == 0">
        <img :src="emptyUrl" alt="" />
        <span>{{ $t('zan-wu-shu-ju') }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, Popup, List, Dialog } from 'vant'
import { getToken } from '@/utils/auth'
import goodsList from '@/components/goodsList/wapList'
import headerBar from '@/components/header'
import { getShopFollowList } from '@/api/shop'
export default {
  components: {
    Button,
    headerBar,
    Popup,
    goodsList,
    List
  },
  data() {
    return {
      form: {},
      showDialog: false,
      dataList: [],
      emptyUrl: require('@/assets/imgs/emtpy.svg'),
      page: {
        current: 1,
        size: 10
      },
      isInit: false,
      loading: false,
      isLoading: false,
      finished: false,
      totalPage: 1,
      pageType: ''
    }
  },
  mounted() {
    this.pageType = this.$route.query.type
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    onLoad() {
      this.page.current += 1
      this.init(true)
    },
    init(isAdd) {
      this.loading = true
      // 店铺列表
      getShopFollowList({
        current: this.page.current,
        pageSize: this.page.size
      })
        .then((res) => {
          if (isAdd) {
            this.dataList = this.dataList.concat(res.data.Items)
          } else {
            this.dataList = res.data.Items
          }
          this.totalPage = res.data.Pagination
            ? res.data.Pagination.totalPage
            : 1
          if (this.totalPage <= this.page.current) {
            this.finished = true
          }
          this.isInit = true
          this.loading = false
        })
        .catch((err) => {
          this.isInit = true
          this.loading = false
        })
    },
    changeTab(i) {
      this.tabIndex = i
    },
    toAdd() {
      this.$router.push({
        name: 'wapAddAddress'
      })
    },
    chooseAddress(data) {
      if (this.pageType != 'choose') {
        return
      }
      sessionStorage.setItem('addressData', JSON.stringify(data))
      sessionStorage.setItem('addressFlag', 1)
      this.back()
    },
    toShop(data) {
      this.$router.push({
        name: 'wapShopInfo',
        query: {
          id: data.Shop.ID
        }
      })
    },
    addressAction(e, data) {
      if (e == 1) {
        //设为默认
        setDefaultAddress({
          ID: data.ID
        }).then((res) => {
          if (res.code == 0) {
            this.page.current = 1
            this.$toast(this.$t('she-zhi-cheng-gong'))

            this.init()
          } else {
            this.$toast(res.msg)
          }
        })
      } else {
        //删除
        Dialog.confirm({
          title: this.$t('que-ren'),
          message: this.$t('que-ren-shan-chu-0')
        }).then(() => {
          deleteAddress({
            ID: data.ID
          }).then((res) => {
            if (res.code == 0) {
              this.page.current = 1
              this.$toast(this.$t('shan-chu-cheng-gong'))
              this.init()
            } else {
              this.$toast(res.msg)
            }
          })
        })
      }
    }
  }
}
</script>